<template>
  <form
    class="ui form"
    :class="{ loading: loading }"
    @submit.prevent="savePost"
    @change="markFormAsDirty"
  >
    <div class="ui stackable grid">
      <!-- [START] Editor Column -->
      <div class="row">
        <div class="four wide column">
          <h3 class="header">Stammdaten</h3>
          <span class="meta"
            >Bearbeite hier die Stammdaten des Termins ein.</span
          >
        </div>
        <div class="twelve wide column">
          <!-- [START] title -->
          <div class="field required">
            <label>Titel</label>
            <input
              type="text"
              placeholder="Titel der Neuigkeit"
              name="title"
              v-model.trim="draftPost.title"
              style="padding: 8px 15px; font-size: 24px; line-height: 36px"
            />
          </div>
          <!-- [END] title -->
          <!-- [START] Content -->
          <div class="field required">
            <label>Beschreibung</label>
            <form-editor :editor="editor" />
          </div>
          <!-- [END] Content -->
        </div>
      </div>
      <!-- [END] Editor Column -->

      <!-- [START] Datum -->
      <div class="row">
        <div class="four wide column">
          <h3 class="header">Datum</h3>
          <span class="meta"
            >Trage hier das Datum der gewünschten Veröffentlichung ein.</span
          >
        </div>
        <div class="four wide column">
          <!-- [START] Date -->
          <div class="field required">
            <label>Datum</label>
            <div class="ui field">
              <input
                type="datetime-local"
                name="created"
                placeholder="Datum wählen…"
                :value="created"
              />
            </div>
          </div>
          <!-- [END] Date -->
        </div>
      </div>

      <!-- [START] Layout -->
      <div class="row">
        <div class="four wide column">
          <h3 class="header">Titelbild</h3>
          <span class="meta">Wähle ein Titelbild für diesen Beitrag aus.</span>
        </div>
        <div class="six wide column">
          <!-- [START] Header Image -->
          <div class="field">
            <label>Titelbild</label>
            <ImageTitleSelect
              :sourceImage="this.post ? this.post.imageUrl : null"
              :targetImage="this.imageUploadPath"
              v-on:setImage="setImage"
              v-on:setImageUploading="setImageUploading"
              v-on:deleteImage="deleteImage"
            />
            <p class="meta" style="margin-top: 10px">
              Kein Bild? Kostenlose Bilder findest du z.B. auf
              <a href="https://unsplash.com/" target="_blank">Unsplash</a>.
            </p>
          </div>
          <!-- [END] Header Image -->
        </div>
      </div>
      <!-- [End] Layout -->

      <!-- [START] Status -->
      <div class="row">
        <div class="four wide column">
          <h3 class="header">Status</h3>
          <span class="meta">Lege die Sichtbarkeit des Beitrags fest.</span>
        </div>
        <div class="five wide column">
          <div class="field required">
            <label>Status</label>
            <select name="visibility" class="ui dropdown" required>
              <option value="">Status wählen…</option>
              <option value="draft">
                <span class="ui empty circular label gray"></span> Entwurf
              </option>
              <option value="review">
                <span class="ui empty circular label yellow"></span> Ausstehende
                Freigabe
              </option>
              <option value="published">
                <span class="ui empty circular label olive"></span>
                Veröffentlicht
              </option>
            </select>
          </div>

          <div
            class="ui message blue"
            v-if="
              post &&
              post.visibility === 'published' &&
              post.created.seconds > new Date().getTime() / 1000
            "
          >
            Dieser Beitrag erscheint
            {{ post.created.seconds | moment("from", "now") }} auf der
            Internetseite.
          </div>
        </div>
      </div>
      <!-- [END] Status -->

      <!-- [START] Buttons: Delete, Save -->
      <div
        class="row"
        style="
          border-top: 1px solid rgba(34, 36, 38, 0.15);
          padding-top: 20px;
          margin-top: 20px;
        "
      >
        <div class="sixteen wide column">
          <div style="float: left" v-if="post && post.id">
            <button
              class="ui basic button red fluid"
              style="margin-bottom: 1em; margin-right: 0; cursor: pointer"
              @click.prevent="showDeleteModal"
            >
              Beitrag löschen
            </button>
          </div>
          <div style="float: right">
            <button
              type="submit"
              class="ui primary button fluid"
              :disabled="!isDirty || imageUploading"
              style="
                margin-bottom: 1em;
                float: right;
                margin-right: 0;
                cursor: pointer;
              "
            >
              {{ post ? "Speichern" : "Erstellen" }}
            </button>
          </div>
        </div>
        <!-- [START] Buttons -->
      </div>
      <!-- [END] Buttons: Delete, Save -->
    </div>

    <!-- Modal Delete -->
    <ModalDelete
      :isModalShowing.sync="isModalShowing"
      :isModalConfirmed.sync="isModalConfirmed"
      @modalConfirmed="deletePost"
      @modalCancelled="modalCancelled"
    ></ModalDelete>
  </form>
</template>

<script>
import { Editor } from "tiptap";
import {
  Blockquote,
  OrderedList,
  BulletList,
  ListItem,
  Bold,
  Italic,
  Link,
  Underline,
  History,
  Heading,
} from "tiptap-extensions";
import FormEditor from "@/components/forms/FormEditor.vue";
import ModalDelete from "@/components/modals/ModalDelete.vue";
import ImageTitleSelect from "@/components/ImageTitleSelect.vue";

export default {
  name: "PostForm",
  props: ["post"],
  components: {
    ModalDelete,
    ImageTitleSelect,
    FormEditor,
  },
  data() {
    return {
      loading: false,
      editor: new Editor({
        extensions: [
          new Blockquote(),
          new BulletList(),
          new OrderedList(),
          new ListItem(),
          new Bold(),
          new Italic(),
          new Link(),
          new Underline(),
          new History(),
          new Heading({ levels: [1, 2, 3] }),
        ],
        linkUrl: null,
        linkMenuIsActive: true,
        onUpdate: ({ getJSON, getHTML }) => {
          if (
            this.draftPost &&
            this.draftPost.contentHtml &&
            this.post &&
            this.post.content &&
            this.draftPost.contentHtml != this.post.content
          ) {
            this.markFormAsDirty();
          }
          this.draftPost.contentJson = getJSON();
          this.draftPost.contentHtml = getHTML();
        },
        content: this.post ? this.post.content : null,
      }),
      isDirty: false,
      isModalShowing: false,
      isModalConfirmed: false,
      imageUploadPath: null,
      imageUploading: false,
      draftPost: {
        title: null,
        contentJson: null,
        contentHtml: null,
        contentDate: null,
      },
    };
  },
  computed: {
    created() {
      if (!this.post || !this.post.created) {
        return this.$moment
          .utc(this.$moment())
          .local()
          .format("YYYY-MM-DDTHH:mm");
      }
      return this.$moment
        .utc(this.post.created.seconds * 1000)
        .local()
        .format("YYYY-MM-DDTHH:mm");
    },
  },
  mounted() {
    $(".ui.dropdown").dropdown();
    this.initEditor();

    if (this.post) {
      this.draftPost.title = this.post.title;
      this.draftPost.contentDate = this.$moment(
        this.post.created.seconds
      ).format("YYYY-MM-DDTHH:mm");
      this.imageUploadPath = this.post.imageUrl;
      $("[name=visibility]").dropdown(
        "set selected",
        this.post && this.post.visibility ? this.post.visibility : "draft"
      );
    }
  },
  methods: {
    initEditor() {
      this.isDirty = false;
    },
    setImage(uploadPath) {
      this.imageUploadPath = uploadPath;
      this.isDirty = true;
    },
    setImageUploading(uploading) {
      this.imageUploading = uploading;
    },
    deleteImage() {
      this.imageUploadPath = null;
      this.isDirty = true;
    },
    savePost() {
      const content = $.trim(
        this.draftPost.contentHtml
          ? this.draftPost.contentHtml
          : this.post.content
      );
      const date = $.trim($("[name=created]").val());
      const visibility = $.trim($("[name=visibility]").val());

      this.loading = true;
      if (!this.post) {
        const self = this;
        return this.$store
          .dispatch("post/create", {
            payload: {
              title: this.draftPost.title,
              content: content,
              visibility: visibility,
              imageUrl: this.imageUploadPath || null,
            },
          })
          .then((result) => {
            this.isDirty = false;
            this.$emit("isDirty", this.isDirty);

            this.isModalConfirmed = true;
            self.$router.push({ name: "post", params: { id: result.id } });
          })
          .catch((error) => console.log("Error creating post", error))
          .finally(() => (this.loading = false));
      } else {
        return this.$store
          .dispatch("post/update", {
            postId: this.post.id,
            payload: {
              title: this.draftPost.title,
              content: content,
              created: this.$moment(date),
              visibility: visibility,
              imageUrl: this.imageUploadPath || null,
            },
          })
          .then(() => {
            this.isDirty = false;
            this.$emit("isDirty", this.isDirty);
          })
          .catch((error) => console.log("Error updating post", error))
          .finally(() => (this.loading = false));
      }
    },
    deletePost() {
      const self = this;
      this.loading = true;

      this.$store
        .dispatch("post/delete", { postId: this.post.id })
        .then(() => {
          this.isDirty = false;
          self.$router.push({ name: "posts" });
        })
        .catch((error) => console.log("Error deleting post", error))
        .finally(() => (this.loading = false));
    },
    markFormAsDirty() {
      this.isDirty = true;
      this.$emit("isDirty", this.isDirty);
    },
    showDeleteModal() {
      this.isModalConfirmed = false;
      this.isModalShowing = true;
    },
    modalCancelled() {
      this.isModalConfirmed = false;
      this.isModalShowing = false;
    },
  },
};
</script>

<style>
.editor {
  margin: 0;
  -webkit-appearance: none;
  tap-highlight-color: rgba(255, 255, 255, 0);
  padding: 0.78571429em 1em;
  background: #fff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  outline: 0;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  -webkit-box-shadow: 0 0 0 0 transparent inset;
  box-shadow: 0 0 0 0 transparent inset;
  -webkit-transition: color 0.1s ease, border-color 0.1s ease;
  transition: color 0.1s ease, border-color 0.1s ease;
  font-size: 16px;
  line-height: 1.2857;
  resize: vertical;
}

#fileInput {
  display: none;
}

#upload-box {
  width: 100%;
  height: 100px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 8px;
  margin: 0 auto;
  background: #fff;
}
.upload-caption {
  display: inline-block;
  margin: 0 auto;
  width: 100%;
  text-align: center;
  vertical-align: middle;
}
</style>
