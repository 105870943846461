<template>
  <div>
    <div v-if="posts && posts.length > 0">
      <table class="ui striped selectable table" style="cursor: pointer">
        <tbody>
          <tr
            v-for="post in posts"
            :key="post.id"
            :class="{ gray: post.visibility === 'draft' }"
            @click="showPost(post)"
          >
            <td class="thirteen wide" style="font-weight: 600">
              <router-link :to="{ name: 'post', params: { id: post.id } }">{{
                post.title
              }}</router-link>
            </td>
            <td class="one wide meta">
              {{ post.created.seconds | moment("L") }}
            </td>
            <td class="one wide" style="width: 3%; padding: 0">
              <span
                class="ui empty circular small label"
                :class="{
                  gray: post.visibility === 'draft',
                  yellow: post.visibility === 'review',
                  olive: post.visibility === 'published',
                }"
              ></span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      class="ui container"
      style="
        border: 1px dotted rgba(0, 0, 0, 0.15);
        border-radius: 8px;
        padding: 15px;
      "
      v-else
    >
      <p>Keine Kurse gefunden.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "PostList",
  props: ["posts"],
  methods: {
    showPost(post) {
      this.$router.push({ name: "post", params: { id: post.id } });
    },
  },
};
</script>
