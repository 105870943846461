<template>
  <div>
    <div class="ui container" style="margin-bottom: 3em">
      <div>
        <h1 class="ui header" style="float: left">Beiträge</h1>

        <router-link
          :to="{ name: 'newPost' }"
          class="ui primary button"
          style="margin-bottom: 1em; float: right; margin-right: 0"
        >
          Neuen Beitrag erstellen
        </router-link>
        <div class="clear" style="clear: both"></div>
      </div>

      <PostList :posts="posts" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import PostList from "@/components/lists/PostList.vue";

export default {
  name: "Posts",
  components: {
    PostList,
  },
  computed: {
    ...mapGetters({
      posts: "post/list",
    }),
  },
};
</script>
