<template>
  <div>
    <div class="ui container" style="margin-bottom: 2em">
      <div style="margin-bottom: 2em">
        <router-link :to="{ name: 'posts' }">
          <i class="arrow left icon" />
          <strong>Alle Beiträge</strong>
        </router-link>
        <h1 style="margin-top: 6px">
          {{
            post && post.title
              ? "Beitrag bearbeiten"
              : "Neuen Beitrag erstellen"
          }}
        </h1>
        <hr style="margin-top: 1em" />
      </div>
    </div>

    <div class="ui container">
      <PostForm :post="post" @isDirty="isDirty" />

      <!-- Modal Cancel -->
      <ModalCancel
        :isModalShowing.sync="isModalShowing"
        :isModalConfirmed.sync="isModalConfirmed"
        @isDirty="isDirty(index, ...arguments)"
        @cancelModalConfirmed="modalConfirmed"
      ></ModalCancel>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PostForm from "@/components/forms/PostForm.vue";
import ModalCancel from "@/components/modals/ModalCancel.vue";

export default {
  name: "Post",
  props: ["id"],
  components: {
    PostForm,
    ModalCancel,
  },
  data() {
    return {
      isModalShowing: false,
      isModalConfirmed: false,
      isFormDirty: false,
      cancelRoute: null,
    };
  },
  computed: {
    ...mapGetters({
      postById: "post/postById",
    }),
    post() {
      return this.postById(this.id);
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.isModalConfirmed || !this.isFormDirty) {
      next();
    } else {
      this.isModalShowing = true;
      this.isModalConfirmed = false;
      this.cancelRoute = to;
      next(false);
    }
  },
  methods: {
    isDirty(isDirty) {
      this.isFormDirty = isDirty;
    },
    modalConfirmed() {
      this.isModalConfirmed = true;
      this.isModalShowing = false;
      this.$router.push(this.cancelRoute);
    },
  },
};
</script>
